import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import "./destributr.css";

function Distribute() {
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    business: "",
    isItems: false,
    distAddress: "",
    shippingAddress: "",
    traffic: "",
    age: "",
    message1: "",
    shipment: "",
    message2: "",

    agree: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const SendMail = async () => {
    const Data = {
      to_name: data.name,
      to_email: data.email,
      mobile: data.mobile,
      companyName: data.companyName,
      business: data.business,
      isItems: data.isItems,
      distAddress: data.distAddress,
      shippingAddress: data.shippingAddress,
      traffic: data.traffic,
      age: data.age,
      message1: data.message1,
      shipment: data.shipment,
      message2: data.message2,
      addrss: data.addrss,
    };

    const Service_id = process.env.REACT_APP_SERVICE_ID;
    const Template_id = process.env.REACT_APP_TEMPLATE_ID;
    const User_id = process.env.REACT_APP_USER_ID;

    try {
      const response = await emailjs.send(
        Service_id,
        Template_id,
        Data,
        User_id
      );
      alert("Message sent successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    SendMail();
    console.log("Form data submitted:", data);
    setData({
      name: "",
      email: "",
      mobile: "",
      companyName: "",
      business: "",
      isItems: false,
      distAddress: "",
      shippingAddress: "",
      traffic: "",
      age: "",
      message1: "",
      shipment: "",
      message2: "",
      potential: "",
      agree: false,
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-center p-2 p-lg-4 pl-xl-4 p-md-3 p-sm-2">
        <h6 className="col-9 text-center ">
          <Link to={"/"}>Home</Link> > Distribute
        </h6>
      </div>

      <section>
        <div className="Distributediv1 d-flex justify-content-center align-items-center ">
          <div className="col-8 col-lg-8 col-md-8 text-light my-3">
            <h1 className="display-1 fw-bold col-12 col-lg-8 distributeH1 ">
              Distribute Free free Water
            </h1>
            <div className="">
              <p className=" desCenter desPara col-12 col-lg-6 px-2 ">
                Whether it's an event or for your business, we have many
                different options for you!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="d-flex justify-content-center">
        <img className="w-100 desImg1" src="./c7.jpeg" alt="" />
      </section>

      <section className="d-flex justify-content-center py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2">
        <div className="col-9 col-lg-5 border row-gap-3 px-2 px-sm-3 px-md-3 px-lg-5">
          <h2 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 text-center">
            Distribute Free free Water
          </h2>

          <form className="row row-gap-4" onSubmit={handleSubmit}>
            <div>
              <label for="" className="">
                First and last name: *
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="name"
                value={data.name}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Email:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="email"
                value={data.email}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Phone number:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="mobile"
                value={data.mobile}
                onChange={handleChange}
              />
            </div>
            {/* <div>
              <label htmlFor="mobile">Mobile:</label>
              <input
                id="mobile"
                type="text"
                name="mobile"
                value={data.mobile}
                onChange={handleChange}
                className="form-control"
              />
            </div> */}

            <div>
              <label for="" className="">
                Company name:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="companyName"
                value={data.companyName}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Type of business:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="business"
                value={data.business}
                onChange={handleChange}
              />
            </div>

            <div className="d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                name="isItems"
                value={data.isItems}
                id="flexCheckDefault"
              />
              <label className="form-check-label " for="flexCheckDefault">
                Is items for an event?
              </label>
            </div>

            <div>
              <label for="" className="">
                Distribution point address:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="distAddress"
                value={data.distAddress}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Shipping address if it is different than the distribution
                point:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="shippingAddress"
                value={data.shippingAddress}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Daily foot traffic:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="number"
                name="traffic"
                value={data.traffic}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Age range:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="number"
                name="age"
                value={data.age}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                How many beverages do you want to distribute monthly?:*
              </label>
              <br />
              <textarea
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                id="exampleFormControlTextarea1"
                rows="3"
                name="message1"
                value={data.message1}
                onChange={handleChange}
              ></textarea>
            </div>

            <div>
              <label for="" className="">
                Do you have an elevated loading dock to receive a shipment?:*
              </label>
              <br />

              <div className="d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  name="shipment"
                  value="Yes"
                  checked={data.shipment === "Yes"}
                />
                <label className="form-check-label " for="flexRadioDefault1">
                  Yes
                </label>
              </div>
              <div className="d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  name="shipment"
                  value="No"
                  checked={data.shipment === "No"}
                  onChange={handleChange}
                />
                <label className="form-check-label " for="flexRadioDefault2">
                  No
                </label>
              </div>
            </div>

            <div>
              <label for="" className="">
                Why do you want to distribute Free free Water?:*
              </label>
              <br />
              <textarea
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                id="exampleFormControlTextarea1"
                rows="3"
                name="message2"
                value={data.message2}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                name="agree"
                checked={data.agree}
                onChange={(e) => setData({ ...data, agree: e.target.checked })}
              />
              <label className="form-check-label " for="flexCheckDefault">
                I agree to share this information with potential advertisers?
              </label>
            </div>

            <div className="text-center">
              <button className="btn btn-info commBtn mb-2">Send</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Distribute;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import emailjs from "emailjs-com";
// import "./destributr.css";

// function Distribute() {
//   const [data, setData] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     companyName: "",
//     business: "",
//     isItems: false,
//     distAddress: "",
//     shippingAddress: "",
//     traffic: "",
//     age: "",
//     message1: "",
//     shipment: "",
//     message2: "",
//     potential: "",
//     agree: false,
//   });

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setData((prevData) => ({
//       ...prevData,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const SendMail = async () => {
//     const Data = {
//       to_name: data.name,
//       to_email: data.email,
//       mobile: data.mobile,
//       companyName: data.companyName,
//       business: data.business,
//       isItems: data.isItems,
//       distAddress: data.distAddress,
//       shippingAddress: data.shippingAddress,
//       traffic: data.traffic,
//       age: data.age,
//       message1: data.message1,
//       shipment: data.shipment,
//       message2: data.message2,
//       potential: data.potential,
//     };
//     const Service_id = process.env.REACT_APP_SERVICE_ID;
//     const Template_id = process.env.REACT_APP_TEMPLATE_ID;
//     const User_id = process.env.REACT_APP_USER_ID;

//     try {
//       await emailjs.send(Service_id, Template_id, data, User_id);
//       alert("Message sent successfully");
//     } catch (error) {
//       console.error("Failed to send email:", error);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     SendMail();
//     console.log("Form data submitted:", data);
//     setData({
//       name: "",
//       email: "",
//       mobile: "",
//       companyName: "",
//       business: "",
//       isItems: false,
//       distAddress: "",
//       shippingAddress: "",
//       traffic: "",
//       age: "",
//       message1: "",
//       shipment: "",
//       message2: "",
//       potential: "",
//       agree: false,
//     });
//   };

//   return (
//     <div>
//       <div className="d-flex justify-content-center p-2 p-lg-4">
//         <h6 className="col-9 text-center">
//           <Link to={"/"}>Home</Link> &gt; Distribute
//         </h6>
//       </div>

//       <section>
//         <div className="Distributediv1 d-flex justify-content-center align-items-center">
//           <div className="col-8 text-light my-3">
//             <h1 className="display-1 fw-bold">Distribute Free Water</h1>
//             <p className="desPara col-12 px-2">
//               Whether it's an event or for your business, we have many different
//               options for you!
//             </p>
//           </div>
//         </div>
//       </section>

//       <section className="d-flex justify-content-center">
//         <img
//           className="w-100 desImg1"
//           src="./c7.jpeg"
//           alt="Distribution Banner"
//         />
//       </section>

//       <section className="d-flex justify-content-center py-4">
//         <div className="col-9 col-lg-5 border px-4">
//           <h2 className="text-center my-4">Distribute Free Water</h2>
//           <form className="row gap-4" onSubmit={handleSubmit}>
//             {/* Name */}
//             <label htmlFor="name">Name:</label>
//             <input
//               id="name"
//               type="text"
//               name="name"
//               value={data.name}
//               onChange={handleChange}
//               className="form-control"
//             />

//             {/* Email */}
//             <label htmlFor="email">Email:</label>
//             <input
//               id="email"
//               type="email"
//               name="email"
//               value={data.email}
//               onChange={handleChange}
//               className="form-control"
//             />

//             {/* Mobile */}
//             <label htmlFor="mobile">Mobile:</label>
//             <input
//               id="mobile"
//               type="text"
//               name="mobile"
//               value={data.mobile}
//               onChange={handleChange}
//               className="form-control"
//             />

//             {/* Company Name */}
//             <label htmlFor="companyName">Company Name:</label>
//             <input
//               id="companyName"
//               type="text"
//               name="companyName"
//               value={data.companyName}
//               onChange={handleChange}
//               className="form-control"
//             />

//             {/* Checkbox Example */}
//             <div className="form-check">
//               <input
//                 id="isItems"
//                 type="checkbox"
//                 name="isItems"
//                 checked={data.isItems}
//                 onChange={handleChange}
//                 className="form-check-input"
//               />
//               <label htmlFor="isItems" className="form-check-label">
//                 Is this for an event?
//               </label>
//             </div>

//             {/* Distribution Address */}
//             <label htmlFor="distAddress">Distribution Point Address:</label>
//             <input
//               id="distAddress"
//               type="text"
//               name="distAddress"
//               value={data.distAddress}
//               onChange={handleChange}
//               className="form-control"
//             />

//             {/* Radio Buttons */}
//             <div>
//               <p>Do you have an elevated loading dock to receive shipments?</p>
//               <input
//                 type="radio"
//                 name="shipment"
//                 value="Yes"
//                 checked={data.shipment === "Yes"}
//                 onChange={handleChange}
//               />
//               Yes
//               <input
//                 type="radio"
//                 name="shipment"
//                 value="No"
//                 checked={data.shipment === "No"}
//                 onChange={handleChange}
//               />
//               No
//             </div>

//             {/* Agreement Checkbox */}
//             <div className="form-check">
//               <input
//                 id="agree"
//                 type="checkbox"
//                 name="agree"
//                 checked={data.agree}
//                 onChange={handleChange}
//                 className="form-check-input"
//               />
//               <label htmlFor="agree" className="form-check-label">
//                 I agree to share this information with potential advertisers.
//               </label>
//             </div>

//             {/* Submit Button */}
//             <div className="text-center">
//               <button type="submit" className="btn btn-info">
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default Distribute;
