import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navi = useNavigate();
  const bar1 = useRef();
  const xmar1 = useRef();

  const HomeReload = () => {
    navi("/");
    window.location.reload();
  };

  const AdvertiseReload = () => {
    navi("/advertise");
    window.location.reload();
  };

  const DistributeReload = () => {
    navi("/distribute");
    window.location.reload();
  };

  const HollReload = () => {
    navi("/Holl");
    window.location.reload();
  };

  const FAQReload = () => {
    navi("/faq");
    window.location.reload();
  };

  const ContactUsReload = () => {
    navi("/contact-us");
    window.location.reload();
  };

  useEffect(() => {
    xmar1.current.style.display = "none";
  }, []);

  const bars = () => {
    bar1.current.style.display = "none";
    xmar1.current.style.display = "block";
  };

  const xmarks = () => {
    bar1.current.style.display = "block";
    xmar1.current.style.display = "none";
  };

  window.onscroll = function () {
    // bar1.current.style.display="block";
    // xmar1.current.style.display="none";
  };

  return (
    <div className="position-sticky top-0 start-0 z-3">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a onClick={HomeReload} className="nav-link d-lg-none " href="#">
            <img width={"65px"} src="./logo.jpeg" alt="" />
          </a>
          <button
            ref={bar1}
            onClick={bars}
            className="navbar-toggler d-lg-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars-staggered"></i>
          </button>

          <button
            onClick={xmarks}
            ref={xmar1}
            className="navbar-toggler d-lg-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-solid fa-xmark"></i>
          </button>

          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100  d-flex align-items-center justify-content-center gap-1 gap-xl-3  gap-lg-3 gap-md-3 gap-sm-2">
              <li className="nav-item">
                <a onClick={HomeReload} className="nav-link " href="#">
                  <img className="" src="./logo.jpeg" alt="" width={"60px"} />
                </a>
              </li>
              <li className="nav-item ">
                <a
                  onClick={HomeReload}
                  className="nav-link"
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>

              <li className="nav-item ">
                <a onClick={AdvertiseReload} className="nav-link" href="#">
                  Advertise
                </a>
              </li>

              <li className="nav-item">
                <a onClick={DistributeReload} className="nav-link" href="#">
                  Distribute
                </a>
              </li>

              {/* <li className="nav-item">
                                <a onClick={HollReload} className="nav-link" href="#">Hall of Fame</a>
                            </li> */}

              <li className="nav-item">
                <a onClick={FAQReload} className="nav-link" href="#">
                  FAQ
                </a>
              </li>

              <li className="nav-item">
                <a onClick={ContactUsReload} className="nav-link" href="#">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
