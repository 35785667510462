import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import "./advertise.css";
function Advertise() {
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    business: "",
    advice: "",
    budget: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const SendMail = async () => {
    const Data = {
      to_name: data.name,
      to_email: data.email,
      mobile: data.mobile,
      companyName: data.companyName,
      business: data.business,
      advice: data.advice,
      budget: data.budget,
      message: data.message,
    };

    const Service_id = process.env.REACT_APP_SERVICE_ID;
    const Template_id = process.env.REACT_APP_TEMPLATE_ID;
    const User_id = process.env.REACT_APP_USER_ID;

    try {
      const response = await emailjs.send(
        Service_id,
        Template_id,
        Data,
        User_id
      );
      alert("Message sent successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    SendMail();
    console.log("Form data submitted:", data);
    setData({
      name: "",
      email: "",
      mobile: "",
      companyName: "",
      business: "",
      advice: "",
      budget: "",
      message: "",
    });
  };
  return (
    <div className=" container-fluid">
      <div className="d-flex   justify-content-center align-items-center p-2 p-lg-4 pl-xl-4 p-md-3 p-sm-2 ">
        <h6 className="col-9 text-center">
          <Link to={"/"}>Home</Link> > Advertise
        </h6>
      </div>

      <section className="d-flex  justify-content-center  my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
        <div className="d-flex flex-wrap col-12 col-lg-8 align-items-center justify-content-center">
          <div className="col-12 col-lg-7 dMainCenter">
            <img className="w-100 ad1Img" src="./cmm.jpeg" alt="" />
          </div>

          <div className="col-12 col-lg-5  text-lg-start my-3 my-lg-0 my-xl-0 my-md-3 my-sm-3">
            <h1 className="fw-bold text-black putAd fs-2">
              PUT YOUR BRAND IN EVERYONE’S HAND™
            </h1>
            <p className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
              Receive meaningful impressions and utilize the greatest brand
              loyalty platform in the ad industry
            </p>
            <p>
              " with our Free free water initiative! Distribute refreshing
              bottled water branded with your logo to make a lasting impression.
              It’s a unique, cost-effective way to engage your audience, build
              brand loyalty, and ensure your message gets into the hands of
              potential customers effortlessly."
            </p>
            <div className="dCenter2">
              <a href="#Get1Jump text-center">
                <button className="btn btn-dark btn-lg text-light ">
                  Get A Quote
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="d-flex adSec2  justify-content-center  my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
        <div className="d-flex flex-wrap col-12 col-lg-8 align-items-center justify-content-center">
          <div className="col-12 col-lg-8">
            <h1 className="fw-bold text-black text-md-center adProducth1">
              Our product is a blank canvas
            </h1>
            <p className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
              Free free Water redefines advertising by transforming it into a
              joyful and engaging experience. Unlike traditional ads that
              disrupt or annoy, we captivate your audience with positivity. Each
              bottle becomes a platform for your brand's message, delivered in a
              way that inspires smiles and builds genuine excitement to support
              your cause.
            </p>

            <div className="d-flex flex-wrap justify-content-center  my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 ">
              <div className="py-3 col-12 col-lg-6">
                <div className="">
                  <h6 className="fw-bold">Use QR Codes</h6>
                  <p>
                    to connect anything on the internet, such as websites,
                    coupons, and videos
                  </p>
                </div>
              </div>

              <div className="py-3 col-12 col-lg-6">
                <div className="">
                  <h6 className="fw-bold">Sell or Freely Distribute</h6>
                  <p>ad supported Free free Water in 500ML / 1L bottles</p>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <h6 className="fw-bold">Choose a Distribution Model</h6>
                  <p>
                    {/* if you want us to distribute it in India or ship it anywhere
                    in the lower 28 states */}
                    Whether you want us to handle distribution in India or ship
                    your brand.Free free Water anywhere in the lower 28 states,
                    we ensure a seamless and effective process.
                  </p>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <h6 className="fw-bold">
                    Subsidize or Cover the Entire Cost by Sharing the Ad Space
                  </h6>
                  {/* <p>with non-competing brands</p> */}
                  <p>
                    Maximize your impact by sharing ad space with non-competing
                    brands.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center  my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
              <a href="#Get1Jump">
                <button className="btn btn-dark btn-lg text-light">
                  Get A Quote
                </button>
              </a>
            </div>
          </div>

          <div className="col-12 col-lg-4  d-flex justify-content-center">
            <img className="img-fluid comImg1" src="./c8.jpeg " alt="" />
          </div>
        </div>
      </section>

      <section className="">
        <div className="d-flex justify-content-center bg-black py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2 Poligon1Advertise">
          <div className="col-8 py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2">
            <div className="d-flex gap-5 flex-wrap justify-content-center align-items-center text-center text-light">
              <div className="col-12 col-lg">
                <img
                  className="img-fluid w-50"
                  src="https://static.wixstatic.com/media/bfcb9b_4700e2f59a614c0dabd343af94cd9952~mv2.webp"
                  alt=""
                />
                <h1 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">10x</h1>
                <p className=" text-info text-center">
                  more impressions than direct mail
                </p>
              </div>

              <div className="col-12 col-lg">
                <img
                  className="img-fluid w-50"
                  src="https://static.wixstatic.com/media/bfcb9b_45630e9492e0437babc8c0283afa0289~mv2.webp"
                  alt=""
                />
                <h1 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
                  Rs: 212
                </h1>
                <p className=" text-info text-center">
                  cheaper per ten impressions than direct mail
                </p>
              </div>

              <div className="col-12 col-lg">
                <img
                  className="img-fluid w-50"
                  src="https://static.wixstatic.com/media/bfcb9b_38d5cbebb5204762946d62ecfa8601f3~mv2.webp"
                  alt=""
                />
                <h1 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">29% </h1>
                <p className=" text-info text-center">
                  drives significant cost savings to return on investment
                </p>
              </div>

              <div className="col-12 col-lg">
                <img
                  className="img-fluid w-50"
                  src="https://static.wixstatic.com/media/bfcb9b_aa2a99c0b5494c6b8dde30f5fc655b02~mv2.webp"
                  alt=""
                />
                <h1 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
                  Priceless
                </h1>
                <p className=" text-info text-center">
                  saving lives and helping the planet
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="d-flex justify-content-lg-end">
          <div className="col-12 col-lg-10 d-flex flex-wrap">
            <div className="col-12 col-lg-7 py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2 billboard01">
              <h1 className="fw-bold text-black py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2 text-center">
                It's a mini billboard in your hands
              </h1>
            </div>

            <div className="bg-info col-12 col-lg-5">
              {/* You can add content here for the info section */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className=" sec4Img d-flex justify-content-center text-center  my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2">
          <div className="col-8">
            <div className="d-flex justify-content-center flex-wrap">
              <div className="col-12 col-lg-4">
                {/* <video className='w-75 ' loop="loop" muted="muted" autoPlay="autoPlay ">
                                    <source src="https://video.wixstatic.com/video/bfcb9b_0c2c123e5714497daf09a2b37a0e12d9/1080p/mp4/file.mp4" type="" />
                                </video> */}

                <img className="img-fluid w-50" src="./c14.jpeg" alt="" />

                <div className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
                  {/* <h2>Paper Carton</h2> */}
                  <p className="text-center">500ml</p>
                  <a href="#Get1Jump">
                    <button className="btn btn-outline-dark btn-lg">
                      Get A Quote
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                {/* <video className='w-75' loop="loop" muted="muted" autoPlay="autoPlay ">
                                    <source src="https://video.wixstatic.com/video/bfcb9b_60be8b0cd37243fb83343b19c5b8091e/1080p/mp4/file.mp4" type="" />
                                </video> */}
                <img className="img-fluid w-50" src="./c8.jpeg" alt="" />

                <div className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
                  {/* <h2>Alumi-Tec Bottle</h2> */}
                  <p className="text-center">500ml</p>
                  <a href="#Get1Jump">
                    <button className="btn btn-outline-dark btn-lg">
                      Get A Quote
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-12 col-lg-4 ">
                {/* <video className='w-75' loop="loop" muted="muted" autoPlay="autoPlay ">
    <source src="https://video.wixstatic.com/video/bfcb9b_60be8b0cd37243fb83343b19c5b8091e/1080p/mp4/file.mp4" type="" />
</video> */}
                <img className="img-fluid w-50 " src="./c2.jpeg" alt="" />

                <div className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
                  {/* <h2>Alumi-Tec Bottle</h2> */}
                  <p className="text-center">500ml</p>
                  <a href="#Get1Jump">
                    <button className="btn btn-outline-dark btn-lg">
                      Get A Quote
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                {/* <video className='w-75' loop="loop" muted="muted" autoPlay="autoPlay ">
    <source src="https://video.wixstatic.com/video/bfcb9b_60be8b0cd37243fb83343b19c5b8091e/1080p/mp4/file.mp4" type="" />
</video> */}
                <img className="img-fluid w-75" src="./cmm.jpeg" alt="" />

                <div className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
                  {/* <h2>Alumi-Tec Bottle</h2> */}
                  <p className="text-center">500ml</p>
                  <a href="#Get1Jump">
                    <button className="btn btn-outline-dark btn-lg">
                      Get A Quote
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                {/* <video className='w-75' loop="loop" muted="muted" autoPlay="autoPlay ">
<source src="https://video.wixstatic.com/video/bfcb9b_60be8b0cd37243fb83343b19c5b8091e/1080p/mp4/file.mp4" type="" />
</video> */}
                <img className="img-fluid w-75 " src="./ff2.png" alt="" />

                <div className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
                  {/* <h2>Alumi-Tec Bottle</h2> */}
                  <p className="text-center">500ml</p>
                  <a href="#Get1Jump">
                    <button className="btn btn-outline-dark btn-lg">
                      Get A Quote
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="d-flex justify-content-center  my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2 ">
          <div className="col-12 col-lg-8">
            <h2 className="text-center">
              Discover the success stories of our satisfied clients.
            </h2>

            <img className="img-fluid px-2 disImg1 " src="./c5.jpeg" alt="" />

            {/* <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src="https://static.wixstatic.com/media/bfcb9b_762924ac61da4524b10104061c92ebcb~mv2.png/v1/fill/w_1225,h_510,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/bfcb9b_762924ac61da4524b10104061c92ebcb~mv2.png" className="d-block w-100" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="https://static.wixstatic.com/media/bfcb9b_c0befc8a059b412ea70c776712737b2a~mv2.png/v1/fill/w_1225,h_510,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/bfcb9b_c0befc8a059b412ea70c776712737b2a~mv2.png" className="d-block w-100" alt="..." />
                                </div>

                                <div className="carousel-item">
                                    <img src="https://static.wixstatic.com/media/bfcb9b_0b81b373a8fa4365965a44453ba95c69~mv2.png/v1/fill/w_1225,h_510,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/bfcb9b_0b81b373a8fa4365965a44453ba95c69~mv2.png" className="d-block w-100" alt="..." />
                                </div>

                                <div className="carousel-item">
                                    <img src="https://static.wixstatic.com/media/bfcb9b_818115bfea52429380a278e184cb18f5~mv2.png/v1/fill/w_1225,h_510,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/bfcb9b_818115bfea52429380a278e184cb18f5~mv2.png" className="d-block w-100" alt="..." />
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>

      <section
        id="Get1Jump"
        className="d-flex justify-content-center py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2 "
      >
        <div className="col-10 col-lg-5 border p-3 my-3 my-lg-4 my-xl-4 my-md-3 my-sm-3">
          <h2 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 text-center">
            Get a Quote
          </h2>

          <form
            className="row row-gap-3 px-2 px-sm-3 px-md-3 px-lg-5"
            onSubmit={handleSubmit}
          >
            <div>
              <label for="" className="">
                First and last name: *
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="name"
                value={data.name}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Email:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="email"
                value={data.email}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Phone number:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="mobile"
                value={data.mobile}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Company name:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="companyName"
                value={data.companyName}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Type of business:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="business"
                value={data.business}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                What do you want to advertise?:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="advice"
                value={data.advice}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                What is your budget?:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="number"
                name="budget"
                value={data.budget}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                Message:*
              </label>
              <br />
              <textarea
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                id="exampleFormControlTextarea1"
                rows="3"
                name="message"
                value={data.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="dCenter text-center">
              <button className="btn btn-sm btn-info commBtn ">Send</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Advertise;
