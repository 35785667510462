import React from "react";

const Thankyou = () => {
  return (
    <div className="text-center mt-5">
      <h1>Thank You!</h1>
      <p>Your message has been submitted successfully.</p>
    </div>
  );
};

export default Thankyou;
