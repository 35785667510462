import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import "./home.css";

function Home() {
  const [data, setData] = useState({
    name: "",
    email: "",
    city: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const SendMail = async () => {
    const Data = {
      to_name: data.name,
      to_email: data.email,
      city: data.city,
    };

    const Service_id = process.env.REACT_APP_SERVICE_ID;
    const Template_id = process.env.REACT_APP_TEMPLATE_ID;
    const User_id = process.env.REACT_APP_USER_ID;

    try {
      const response = await emailjs.send(
        Service_id,
        Template_id,
        Data,
        User_id
      );
      alert("Message sent successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    SendMail();
    console.log("Form data submitted:", data);
    setData({
      name: "",
      email: "",

      city: "",
    });
  };
  return (
    <div>
      {/* section 1 start */}

      <section className="text-center my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 ">
        <p style={{ fontSize: "30px" }} className="text-center  ">
          Put Your Brand In Everyone's Hand!
        </p>

        <Link
          to={"/Advertise"}
          className="btn btn-outline-dark rounded-0 py-2 px-4"
        >
          Advertise with us
        </Link>
        <br />

        <img
          className="mt-3 col-12 col-lg-5 px-2 homeImg2"
          src="./c4.jpeg"
          alt=""
        />
      </section>
      {/* section 1 end */}

      {/* section 2 start */}

      <section className="text-center my-lg-2  d-flex justify-content-center static2">
        <div className="row col-12 col-lg-6 my-lg-2">
          <div className="col-12 col-xl-6 p-lg-4">
            <img
              className="img-fluid "
              src="https://static.wixstatic.com/media/bfcb9b_b49827742e3f4fd1b62d97bedd6e0d82~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/image.png"
              alt=""
            />
            <h5>Save Money</h5>
            <p style={{ fontSize: "18px" }} className="text-center">
              Bottled water costs 2,000 times the price of tap water
            </p>
          </div>

          <div className="col-12 col-xl-6  p-lg-4">
            <img
              className="img-fluid"
              src="https://static.wixstatic.com/media/bfcb9b_8428e536e2c84887be07349a8567d661~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/image.png"
              alt=""
            />
            <h5>Eco Friendly</h5>
            <p style={{ fontSize: "18px" }} className="text-center">
              Our goal is to be net positive by 2030
            </p>
          </div>

          <div className="col-12 col-xl-6 p-lg-4">
            <img
              className="img-fluid"
              src="https://static.wixstatic.com/media/bfcb9b_d86c93d475f044b98a98894752b2969b~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/image.png"
              alt=""
            />
            <h5>Premium Water</h5>
            <p style={{ fontSize: "18px" }} className="text-center">
              Free free Water is natural spring water and all of our containers
              are BPA free
            </p>
          </div>

          <div className="col-12 col-xl-6 p-lg-4">
            <img
              className="img-fluid"
              src="https://static.wixstatic.com/media/bfcb9b_918f7ef38a0a42358efab157edf47c31~mv2.png/v1/fill/w_104,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/image.png"
              alt=""
            />
            <h5>Save Lives</h5>
            <p style={{ fontSize: "18px" }} className="text-center">
              Fifty paisa from each drink is donated to trusted charities that
              build water wells for communities in need
            </p>
          </div>
        </div>
      </section>

      {/* section 2 end */}

      {/* section 3 start */}

      <section className=" my-2 my-lg-3 my-xl-3 my-md-3 my-sm-2 d-flex justify-content-center">
        <div className="row justify-content-center col-12 col-lg-7  my-1 my-lg-4 my-xl-4 my-md-3 my-sm-2 align-items-center">
          <div className="col-12 d-flex justify-content-center col-lg py-1 py-xl-2  py-lg-2  py-md-1 py-sm-1">
            {/* <video className='img-fluid' loop="loop" muted="muted" autoPlay="autoplay">

                            <source src='https://video.wixstatic.com/video/bfcb9b_7acc406637ce4727ad0391a8ef6b86fb/720p/mp4/file.mp4'></source>

                        </video> */}

            <img className="img-fluid comImg1" src="./c8.jpeg" alt="" />
          </div>

          <div className="col-12 col-lg-7 py-1 py-xl-2  py-lg-2  py-md-1 py-sm-1">
            <p style={{ fontSize: "18px" }}>
              Free free Water is an innovative advertising platform that
              utilizes free premium spring water as a new type of advertising
              medium while prioritizing philanthropy and sustainability. Fifty
              paisa from each beverage is donated to charity to{" "}
              <b>fight the India water crisis.</b> We only need 10% of Americans
              to choose Free free Water to solve the global water crisis.
            </p>
          </div>
        </div>
      </section>

      {/* section 3 end */}

      {/* section 4 start */}

      <section className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 d-flex justify-content-center">
        <div className="row col-12 col-lg-10 col-xxl-7 align-items-center   my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2">
          <div className="col-12 col-lg-6 py-2">
            <h1 className="fw-bold">We are partnered with NGO's </h1>
            <p className="py-2 py-lg-4 py-xl-4 py-md-3 py-sm-2">
              We donate fifty paisa per beverage to WellAware, an Indian based
              non-profit that builds water wells in Rajasthan. Every 150 Free
              free Water donates enough money to provide one person with a safe
              water source for the rest of their life. Rajasthan is one of the
              driest regions in India, where clean and accessible water is a
              rare resource.
            </p>
            <div className="dCenter2">
              <button className="btn btn-outline-dark fw-bold">
                Learn More
              </button>
            </div>
          </div>

          <div className="col-12  col-lg-6 py-2  dMainCenter ">
            <img
              className=" object-fit-cover homeImg1 px-2 "
              //   src="https://static.wixstatic.com/media/bfcb9b_3c6cf264b29e49838779345fd1a18d20~mv2.webp/v1/fill/w_649,h_488,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/free-water-drinking-image-1.webp"
              src="./Raja13.jpg"
              alt=""
            />
          </div>
        </div>
      </section>

      {/* section 4 end */}

      {/* section 5 start */}

      {/* <section className='my-lg-5  d-flex justify-content-center'>

                <div className='row col-12 col-lg-7 align-items-center my-lg-5'>

                    <div className='col-12 col-lg-7 py-2'>
                        <img className='w-100 object-fit-cover' src="https://static.wixstatic.com/media/bfcb9b_54b4f8a74eee4e4295f957edee9217bd~mv2.webp/v1/fill/w_621,h_455,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/three-bottles-free-water.webp" alt="" />
                    </div>

                    <div className='col-12 col-lg-5 py-2'>
                        <h1 className='fw-bold'>We never use plastic bottles</h1>
                        <p className='py-4'>FreeWater comes in aluminum bottles and paper cartons of natural spring water because we didn't want to make water free and do it in a plastic bottle.</p>
                    </div>


                </div>

            </section> */}

      {/* section 5 end */}

      {/* section 6 start */}

      <section className="d-flex justify-content-center py-lg-4" id="Get1Jump">
        <div className="col-10 col-lg-5 border p-3 my-3 my-lg-4 my-xl-4 my-md-3 my-sm-3">
          <h2 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 fw-bold text-black text-center">
            Subscribe To Get Your Free free Water
          </h2>

          <form
            className="row row-gap-3 px-2 px-sm-3 px-md-3 px-lg-5 "
            onSubmit={handleSubmit}
          >
            <div>
              <label for="" className="">
                First and last name: *
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="name"
                value={data.name}
                onChange={handleChange}
              />
            </div>

            {/* <div>
              <label for="" className="">
                Last name: *
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
              />
            </div> */}

            <div>
              <label for="" className="">
                Email:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="email"
                value={data.email}
                onChange={handleChange}
              />
            </div>

            <div>
              <label for="" className="">
                City:*
              </label>
              <br />
              <input
                className="form-control p-0 p-sm-1 p-md-1 p-lg-1 fs-5 rounded-0 border-0 "
                type="text"
                name="name"
                value={data.city}
                onChange={handleChange}
              />
            </div>

            <div className="dCenter text-center">
              <button className="btn btn-sm btn-info commBtn ">Send</button>
            </div>
          </form>
        </div>
      </section>

      {/* section 6 end */}
    </div>
  );
}

export default Home;
