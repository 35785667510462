import React, { useState } from "react";
import { Link } from "react-router-dom";

function FAQ() {
  const [activeId1, setActiveId1] = useState(null);
  const [activeId2, setActiveId2] = useState(null);
  const [activeId3, setActiveId3] = useState(null);
  const [activeId4, setActiveId4] = useState(null);

  const toggleCollapse1 = (id) => {
    setActiveId1(activeId1 === id ? null : id);
  };
  const toggleCollapse2 = (id) => {
    setActiveId2(activeId2 === id ? null : id);
  };
  const toggleCollapse3 = (id) => {
    setActiveId3(activeId3 === id ? null : id);
  };
  const toggleCollapse4 = (id) => {
    setActiveId4(activeId4 === id ? null : id);
  };

  return (
    <div className="mainFaq">
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center ">
          <div className="col-12 col-lg-9  ">
            <div className="d-flex justify-content-center  p-2 p-lg-4 pl-xl-4 p-md-3 p-sm-2">
              <h6 className="  text-center">
                <Link to={"/"}>Home</Link> > FAQ
              </h6>
            </div>
            <div className="">
              <p className="px-xl-2 px-lg-2 ">
                Free free Water offers branded and eco friendly water bottles,
                which can be distributed either for free or at a reduced cost.
                You can choose to distribute locally in India or across the
                lower 28 states. To cover the cost, you can share ad space on
                the bottles, allowing you to subsidize or fully cover expenses
                while promoting your brand. This innovative model creates a
                unique advertising opportunity, connecting your brand with a
                wide audience in a refreshing, cost-effective way.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row container-fluid justify-content-center">
        <div className="col-12 col-lg-9">
          <h3 className="text-center py-lg-3 py-xl-3 py-md-2 py-2">General</h3>

          {[
            {
              id: 1,
              question: "How water is free?",
              answer:
                "Free free Water is paid for by ads that are printed directly onto eco friendly bottles and paper cartons. fifty paisa  per beverage is donated to charity to fight the global water crisis.",
            },
            {
              id: 2,
              question: "Will you ship Free free Water to me?",
              answer:
                "We are working around the clock to launch our free 12 pack program. Just pay for the shipping and the water is free and each water donates to charity too.",
            },
            {
              id: 3,
              question: "Why Bisphenol A (BPA) is free?",
              answer:
                "The majority of bottled water is contaminated with the chemical BPA. High levels of BPA have been linked to diseases such as hormonal disruption, diabetes, high blood pressure, infertility, breast cancer, and hazardous effects on the brain and behavior of infants and young children.",
            },
            // {
            //   id: 4,
            //   question: "How do I invest?",
            //   answer:
            //     "Please visit and fill out the form making sure to type a  investor as a subject line. http://freefree.in/ContactUs",
            // },
            {
              id: 4,
              question: "How can I help Free free Water?",
              answer:
                "Please help us to spread the word by sharing our project on social media and with your friends and family.",
            },
          ].map((item) => (
            <div key={item.id} className="border-bottom border-info py-3">
              <div
                className="d-flex justify-content-between align-items-center"
                role="button"
                onClick={() => toggleCollapse1(item.id)}
              >
                <h5>{item.question}</h5>
                <h3>{activeId1 === item.id ? "-" : "+"}</h3>
              </div>
              {activeId1 === item.id && (
                <p className="fagP mt-2 ">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="row container-fluid justify-content-center">
        <div className="col-12 col-lg-9">
          <h3 className="text-center py-lg-3 py-xl-3 py-md-2 py-2">
            Distribution
          </h3>

          {[
            {
              id: 1,
              question: "How do I distribute Free free Water?",
              answer:
                "We are currently looking to partner with distribution points in the lower 28 states. In most cases, our ideal distribution partnerships are with retail stores and outlets. However, we may be willing to partner with the right individuals to distribute Free free Water in their town. Please fill out the contact form on our distribution page / leave a detailed message and someone from our team will get back to you ASAP.",
            },
            {
              id: 2,
              question:
                "What are the minimum requirements to be a distributor?",
              answer:
                "The location needs to be enticing enough to line up advertisers to cover the cost of the product/distribution.   Each location will need to advertise that they are a Free free Water distributor by posting a sign on their window and on their website if applies. We will also market your location for you.Individuals who wish to “officially” distribute Free free Water must be hand selected by our team to ensure that they are a good fit.",
            },
            {
              id: 3,
              question: "Do distributers have to advertise?",
              answer:
                "No, but that is the fastest way to get started. Distributors are not obligated to advertise, but doing so can significantly accelerate their success. Advertising increases visibility, attracts potential customers, and establishes brand awareness. While other methods like word-of-mouth and networking can work, advertising ensures faster market penetration, helping distributors reach their target audience efficiently and gain a competitive edge.",
            },
          ].map((item) => (
            <div key={item.id} className="border-bottom border-info py-3">
              <div
                className="d-flex justify-content-between align-items-center"
                role="button"
                onClick={() => toggleCollapse2(item.id)}
              >
                <h5>{item.question}</h5>
                <h3>{activeId2 === item.id ? "-" : "+"}</h3>
              </div>
              {activeId2 === item.id && (
                <p className="fagP mt-2 ">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="row container-fluid justify-content-center">
        <div className="col-12 col-lg-9">
          <h3 className="text-center py-lg-3 py-xl-3 py-md-2 py-2">
            Advertising
          </h3>

          {[
            {
              id: 1,
              question: "How much does it cost to advertise?",
              answer: (
                <span>
                  It depends on several factors: <br />
                  1. The number of units. <br />
                  2. Are we distributing the water, or are you distributing the
                  water? <br />
                  3. The location of distribution.
                  <br /> 4. Do you have a graphic designer on staff, or do you
                  need us to design it for you? <br />
                  5. Are you splitting the ad space with someone else?
                </span>
              ),
            },
            {
              id: 2,
              question: "How long does it take to place an advertising order?",
              answer:
                "Roughly six weeks from design approval and payment. However, you should always place your order as far in advance as possible if you have a time sensitive project.",
            },
            {
              id: 3,
              question:
                "How does Free free Water compare to other advertising mediums?",
              answer:
                "Free free Water is the best brand loyalty platform on Earth and there are unique opportunities to incorporate this new and exciting medium into most ad budgets.",
            },
            {
              id: 4,
              question: "How do I advertise?",
              answer: (
                <span>
                  Advertising with Free free Water is an innovative way to
                  connect with your audience while supporting a mission-driven
                  platform that prioritizes sustainability and philanthropy. Our
                  unique model turns free premium spring water into a blank
                  canvas for your brand’s message, delivering your content
                  directly into the hands of engaged consumers.To advertise,
                  please visit{" "}
                  <a
                    href="http://freefree.in"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    freefree.in
                  </a>
                </span>
              ),
            },
            {
              id: 5,
              question: "How do I advertise with Free free Water?",
              answer: (
                <span>
                  To advertise with Free free Water, please visit{" "}
                  <a
                    href="http://freefree.in/advertise"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    freefree.in/advertise
                  </a>
                </span>
              ),
            },
          ].map((item) => (
            <div key={item.id} className="border-bottom border-info py-3">
              <div
                className="d-flex justify-content-between align-items-center"
                role="button"
                onClick={() => toggleCollapse3(item.id)}
              >
                <h5>{item.question}</h5>
                <h3>{activeId3 === item.id ? "-" : "+"}</h3>
              </div>
              {activeId3 === item.id && (
                <p className="fagP mt-2 ">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      {/* <div className="row container-fluid justify-content-center">
        <div className="col-12 col-lg-9">
          <h3 className="text-center py-lg-3 py-xl-3 py-md-2 py-2">Other</h3>

          {[
            {
              id: 1,
              question: "Can I use Free free Water as a fundraising tool?",
              answer:
                "Free free Water will create special partnerships for 501(c)(3) organizations or any other positive community outlets such as church groups, sports programs, and schools. Partners who are using Free free Water as a fundraiser must be able to sell all the ad space themselves and we will contribute between ten and twenty five cents per beverage to the partner depending on the quantity and the price at which the product is sold.",
            },
            {
              id: 2,
              question: "Will you offer other free products in the future?",
              answer:
                "Yes, we are launching a free supermarket / Amazon 2.0, and Free free Water is simply our first product. We have created a handful of processes that will enable the free and profitable distribution of nearly every type of product that can be purchased in COSTCO today. This includes food, beverages, clothing, medicine, computers, transportation, and travel. Each category of product will donate a percentage of its revenue to a different charitable cause.",
            },
            {
              id: 3,
              question: "How do I invest?",
              answer:
                "Please visit and fill out the form making sure to type a investor  as a subject line. http://freefree.in/ContactUs",
            },
          ].map((item) => (
            <div key={item.id} className="border-bottom border-info py-3">
              <div
                className="d-flex justify-content-between align-items-center"
                role="button"
                onClick={() => toggleCollapse4(item.id)}
              >
                <h5>{item.question}</h5>
                <h3>{activeId4 === item.id ? "-" : "+"}</h3>
              </div>
              {activeId4 === item.id && (
                <p className="fagP mt-2 ">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div> */}
      <div className="row container-fluid justify-content-center">
        <div className="col-12 col-lg-9">
          <h3 className="text-center py-lg-3 py-xl-3 py-md-2 py-2">Other</h3>

          {[
            {
              id: 1,
              question: "Can I use Free free Water as a fundraising tool?",
              answer: (
                <span>
                  Free free Water will create special partnerships for 501(c)(3)
                  organizations or any other positive community outlets such as
                  church groups, sports programs, and schools. Partners who are
                  using Free free Water as a fundraiser must be able to sell all
                  the ad space themselves and we will contribute between ten and
                  twenty five cents per beverage to the partner depending on the
                  quantity and the price at which the product is sold.
                </span>
              ),
            },
            {
              id: 2,
              question: "Will you offer other free products in the future?",
              answer: (
                <span>
                  Yes, we are launching a free supermarket / Amazon 2.0, and
                  Free free Water is simply our first product. We have created a
                  handful of processes that will enable the free and profitable
                  distribution of nearly every type of product that can be
                  purchased in COSTCO today. This includes food, beverages,
                  clothing, medicine, computers, transportation, and travel.
                  Each category of product will donate a percentage of its
                  revenue to a different charitable cause.
                </span>
              ),
            },
            {
              id: 3,
              question: "How do I invest?",
              answer: (
                <span>
                  Please visit and fill out the form making sure to type an
                  investor as a subject line.{" "}
                  <a
                    href="http://freefree.in/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    freefree.in/contact-us
                  </a>
                </span>
              ),
            },
          ].map((item) => (
            <div key={item.id} className="border-bottom border-info py-3">
              <div
                className="d-flex justify-content-between align-items-center"
                role="button"
                onClick={() => toggleCollapse4(item.id)}
              >
                <h5>{item.question}</h5>
                <h3>{activeId4 === item.id ? "-" : "+"}</h3>
              </div>
              {activeId4 === item.id && (
                <p className="fagP mt-2">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
