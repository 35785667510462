import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import "./contact.css";

function ContactUs() {
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    city: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const SendMail = async () => {
    const Data = {
      to_name: data.name,
      to_email: data.email,
      subject: data.subject,
      mobile: data.mobile,
      message: data.message,
    };

    const Service_id = process.env.REACT_APP_SERVICE_ID;
    const Template_id = process.env.REACT_APP_TEMPLATE_ID;
    const User_id = process.env.REACT_APP_USER_ID;

    try {
      const response = await emailjs.send(
        Service_id,
        Template_id,
        Data,
        User_id
      );
      alert("Message sent successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    SendMail();
    console.log("Form data submitted:", data);
    setData({
      name: "",
      email: "",
      message: "",
      mobile: "",
      city: "",
      subject: "",
    });
  };
  return (
    <div>
      <div id="ravi" className="d-flex  justify-content-center">
        <div className="col-12 col-lg-7 ">
          <h6 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 text-center  ">
            <Link to={"/"}>Home</Link> > Contacts
          </h6>
          <div className=" d-flex justify-content-center align-items-center">
            {" "}
            <p className=" ">
              For inquiries or to get involved with Free free Water, reach us at
              contact@
              <span>
                <a href="http://freefree.in/">freefree.in. </a>
              </span>
              Visit our website at{" "}
              <span>
                <a href="http://freefree.in/contact-us">
                  freefree.in/contact-us{" "}
                </a>
              </span>{" "}
              for more information. Follow us on social media for updates and
              news:{" "}
              <span>
                <a href="http://freefree.in/">freefree.in </a>
              </span>
              . We’re excited to connect and collaborate with you!
            </p>
          </div>
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div className="col-10 col-lg-7 col-lg border  my-3 my-lg-4 my-xl-4 my-md-3 my-sm-3">
              <h2 className=" my-2 my-lg-4 my-xl-4 my-md-3 my-sm-2 px-2 text-center">
                Contacts
              </h2>

              <form
                className="row row-gap-3 px-2 px-sm-3 px-md-3 px-lg-5"
                onSubmit={handleSubmit}
              >
                <div>
                  <label> First and last name: *</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label>Email:*</label>
                  <br />
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label>Phone number:*</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    name="phoneNumber"
                    value={data.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label>Subject:*</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    name="subject"
                    value={data.subject}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label>Message:*</label>
                  <br />
                  <textarea
                    className="form-control"
                    name="message"
                    value={data.message}
                    onChange={handleChange}
                    rows="3"
                    required
                  ></textarea>
                </div>

                <div className="text-center">
                  <button className="btn btn-info commBtn mb-3" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>

            <div className="col-12  col-lg-5 text-center">
              <img className="img-fluid conatctImg2 " src="./c14.jpeg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
